/* -- fade transition --*/
.fade-enter {
    opacity: 0;
  }
  
  .fade-enter-active {
    opacity: 1;
    transition: all 200ms ease-in-out;
  }
  
  .fade-exit {
    opacity: 1;
    transform: scale(1);
  }
  
  .fade-exit-active {
    opacity: 0;
    transform: scale(1.2);
    transition: all 200ms 300ms ease-in-out;
  }
  
  /* -- fade & zoom transition --*/
  .fade-zoom-enter {
    opacity: 0;
    transform: scale(1.1);
  }
  
  .fade-zoom-enter-active {
    opacity: 1;
    transform: scale(1);
    transition: all 200ms ease-in-out;
  }
  
  .fade-zoom-exit {
    opacity: 1;
    transform: scale(1);
  }
  
  .fade-zoom-exit-active {
    opacity: 0;
    transform: scale(1.1);
    transition: all 200ms ease-in-out;
  }
  
  /* -- upload transition --*/
  .upload-enter {
    opacity: 0;
    transform: scale(1.1);
  }
  
  .upload-enter-active {
    opacity: 0.75;
    transform: scale(1);
    transition: all 200ms ease-in-out;
  }
  
  .upload-exit {
    opacity: 0.75;
    transform: scale(1);
  }
  
  .upload-exit-active {
    opacity: 0;
    transform: scale(1.1);
    transition: all 200ms ease-in-out;
  }
  
  /* -- workbench transition --*/
  .bench-enter {
    opacity: 0;
    transform: scale(0.5);
  }
  
  .bench-enter-active {
    opacity: 1;
    transform: scale(1);
    transition: all 200ms ease-in-out;
  }
  
  .bench-exit {
    opacity: 1;
    transform: scale(1);
  }
  
  .bench-exit-active {
    opacity: 0;
    transform: scale(0.5);
    transition: all 200ms ease-in-out;
  }
  
  /* -- modal transition --*/
  .modal-enter {
    opacity: 0;
    transform: scale(0.9);
  }
  
  .modal-enter-active {
    opacity: 1;
    transform: scale(1);
    transition: all 200ms ease-in-out;
  }
  
  .modal-exit {
    opacity: 1;
    transform: scale(1);
  }
  
  .modal-exit-active {
    opacity: 0;
    transform: scale(0.9);
    transition: all 200ms ease-in-out;
  }
  