@import url(https://use.typekit.net/mas4cyz.css);
@import url(https://fonts.googleapis.com/css?family=Noto+Sans+JP:900);
@import url(https://fonts.googleapis.com/css?family=Kanit:400,600,700);
* {
  box-sizing: border-box;
}

a {
  text-decoration: none;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Kanit', sofia-pro, Helvetica, arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  left: 0;
  top: 0;
}

#root {
  position: relative;
}

.user-container {
  position: relative;
  padding-bottom: 100%;
  width: 100%;
  overflow: hidden;
}

.overlay {
  position: absolute;
  left: -1%;
  top: -1%;
  height: 102%;
  width: 102%;
}

.overlay.--red {
  background: -webkit-gradient(linear, left top, left bottom, from(rgb(255, 8, 68)), to(rgb(255, 177, 153)));
  background: -webkit-linear-gradient(rgb(255, 8, 68), rgb(255, 177, 153));
  background: linear-gradient(rgb(255, 8, 68), rgb(255, 177, 153));
}

.overlay.--orange {
  background: -webkit-gradient(linear, left top, left bottom, from(rgb(246, 211, 101)), to(rgb(253, 160, 133)));
  background: -webkit-linear-gradient(rgb(246, 211, 101), rgb(253, 160, 133));
  background: linear-gradient(rgb(246, 211, 101), rgb(253, 160, 133));
}

.overlay.--pink {
  background: -webkit-gradient(linear, left top, left bottom, from(rgb(250, 208, 196)), to(rgb(255, 209, 255)));
  background: -webkit-linear-gradient(rgb(250, 208, 196), rgb(255, 209, 255));
  background: linear-gradient(rgb(250, 208, 196), rgb(255, 209, 255));
}

.overlay.--blue {
  background: -webkit-gradient(linear, left top, left bottom, from(rgb(161, 196, 253)), to(rgb(194, 233, 251)));
  background: -webkit-linear-gradient(rgb(161, 196, 253), rgb(194, 233, 251));
  background: linear-gradient(rgb(161, 196, 253), rgb(194, 233, 251));
}

.overlay.--purple {
  background: -webkit-gradient(linear, left top, left bottom, from(rgb(161, 140, 209)), to(rgb(251, 194, 235)));
  background: -webkit-linear-gradient(rgb(161, 140, 209), rgb(251, 194, 235));
  background: linear-gradient(rgb(161, 140, 209), rgb(251, 194, 235));
}

.overlay.--rose {
  background: -webkit-gradient(linear, left top, left bottom, from(rgb(255, 199, 150)), to(rgb(255, 107, 149)));
  background: -webkit-linear-gradient(rgb(255, 199, 150), rgb(255, 107, 149));
  background: linear-gradient(rgb(255, 199, 150), rgb(255, 107, 149));
}

.eHfxSV {
  position: absolute;
  left: 0px;
  top: 0px;
  height: 100%;
  width: 100%;
  display: block;
  object-fit: cover;
}

.gWRWlT {
  position: absolute;
  display: block;
  object-fit: cover;
  left: -1%;
  top: -1%;
  height: 102%;
  width: 102%;
  -webkit-filter: blur(0.5vw);
          filter: blur(0.5vw);
  opacity: 0;
  visibility: hidden;
  -webkit-transition: opacity 200ms ease-in-out 0s;
  transition: opacity 200ms ease-in-out 0s;
}

.hash-container {
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.hash-label {
  color: white;
  font-size: 4vw;
  font-weight: 700;
  position: relative;
  margin: 0px;
  padding: 0px;
}
/* -- fade transition --*/
.fade-enter {
    opacity: 0;
  }
  
  .fade-enter-active {
    opacity: 1;
    -webkit-transition: all 200ms ease-in-out;
    transition: all 200ms ease-in-out;
  }
  
  .fade-exit {
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  
  .fade-exit-active {
    opacity: 0;
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
    -webkit-transition: all 200ms 300ms ease-in-out;
    transition: all 200ms 300ms ease-in-out;
  }
  
  /* -- fade & zoom transition --*/
  .fade-zoom-enter {
    opacity: 0;
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  
  .fade-zoom-enter-active {
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transition: all 200ms ease-in-out;
    transition: all 200ms ease-in-out;
  }
  
  .fade-zoom-exit {
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  
  .fade-zoom-exit-active {
    opacity: 0;
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
    -webkit-transition: all 200ms ease-in-out;
    transition: all 200ms ease-in-out;
  }
  
  /* -- upload transition --*/
  .upload-enter {
    opacity: 0;
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  
  .upload-enter-active {
    opacity: 0.75;
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transition: all 200ms ease-in-out;
    transition: all 200ms ease-in-out;
  }
  
  .upload-exit {
    opacity: 0.75;
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  
  .upload-exit-active {
    opacity: 0;
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
    -webkit-transition: all 200ms ease-in-out;
    transition: all 200ms ease-in-out;
  }
  
  /* -- workbench transition --*/
  .bench-enter {
    opacity: 0;
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
  }
  
  .bench-enter-active {
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transition: all 200ms ease-in-out;
    transition: all 200ms ease-in-out;
  }
  
  .bench-exit {
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  
  .bench-exit-active {
    opacity: 0;
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
    -webkit-transition: all 200ms ease-in-out;
    transition: all 200ms ease-in-out;
  }
  
  /* -- modal transition --*/
  .modal-enter {
    opacity: 0;
    -webkit-transform: scale(0.9);
            transform: scale(0.9);
  }
  
  .modal-enter-active {
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transition: all 200ms ease-in-out;
    transition: all 200ms ease-in-out;
  }
  
  .modal-exit {
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  
  .modal-exit-active {
    opacity: 0;
    -webkit-transform: scale(0.9);
            transform: scale(0.9);
    -webkit-transition: all 200ms ease-in-out;
    transition: all 200ms ease-in-out;
  }
  
