@import url('https://use.typekit.net/mas4cyz.css');
@import url('https://fonts.googleapis.com/css?family=Noto+Sans+JP:900');
@import url('https://fonts.googleapis.com/css?family=Kanit:400,600,700');

* {
  box-sizing: border-box;
}

a {
  text-decoration: none;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Kanit', sofia-pro, Helvetica, arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  left: 0;
  top: 0;
}

#root {
  position: relative;
}

.user-container {
  position: relative;
  padding-bottom: 100%;
  width: 100%;
  overflow: hidden;
}

.overlay {
  position: absolute;
  left: -1%;
  top: -1%;
  height: 102%;
  width: 102%;
}

.overlay.--red {
  background: linear-gradient(rgb(255, 8, 68), rgb(255, 177, 153));
}

.overlay.--orange {
  background: linear-gradient(rgb(246, 211, 101), rgb(253, 160, 133));
}

.overlay.--pink {
  background: linear-gradient(rgb(250, 208, 196), rgb(255, 209, 255));
}

.overlay.--blue {
  background: linear-gradient(rgb(161, 196, 253), rgb(194, 233, 251));
}

.overlay.--purple {
  background: linear-gradient(rgb(161, 140, 209), rgb(251, 194, 235));
}

.overlay.--rose {
  background: linear-gradient(rgb(255, 199, 150), rgb(255, 107, 149));
}

.eHfxSV {
  position: absolute;
  left: 0px;
  top: 0px;
  height: 100%;
  width: 100%;
  display: block;
  object-fit: cover;
}

.gWRWlT {
  position: absolute;
  display: block;
  object-fit: cover;
  left: -1%;
  top: -1%;
  height: 102%;
  width: 102%;
  filter: blur(0.5vw);
  opacity: 0;
  visibility: hidden;
  transition: opacity 200ms ease-in-out 0s;
}

.hash-container {
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  overflow: hidden;
}

.hash-label {
  color: white;
  font-size: 4vw;
  font-weight: 700;
  position: relative;
  margin: 0px;
  padding: 0px;
}